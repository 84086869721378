<template>
	<Breadcrumbs></Breadcrumbs>
	<MDBCard class="m-3">
    <MDBCardBody>
			<transition name="fade">
				<PageLoading v-if="PageLoading"></PageLoading>
			</transition>
			<MDBRow>
				<MDBCol class="mb-3" sm="12" md="4">
					<MDBCard
						bg="white"
					>
						<MDBCardBody>
							<MDBCardTitle> 總銷售量 {{order.all}} </MDBCardTitle>
							<MDBCardText>
								<div> + {{order.today}} </div>
								<div>銷售數量</div>
							</MDBCardText>
						</MDBCardBody>
					</MDBCard>
				</MDBCol>
				<MDBCol class="mb-3" sm="12" md="4">
					<MDBCard
						bg="white"
					>
						<MDBCardBody>
							<MDBCardTitle> 會員總數 {{member.all}} </MDBCardTitle>
							<MDBCardText>
								<div> + {{member.today}} </div>
								<div>新會員</div>
							</MDBCardText>
						</MDBCardBody>
					</MDBCard>
				</MDBCol>
				<MDBCol class="mb-3" sm="12" md="4">
					<MDBCard
						bg="white"
					>
						<MDBCardBody>
							<MDBCardTitle> 論壇總貼文 {{article.all}} </MDBCardTitle>
							<MDBCardText>
								<div> + {{article.today}} </div>
								<div>論壇貼文</div>
							</MDBCardText>
						</MDBCardBody>
					</MDBCard>
				</MDBCol>
			</MDBRow>
			<DataTable
				:header="headers"
				:tabledata="order.new"
				:pagination="pagination"
			>
				<!-- 時間轉換 -->
				<template v-slot:created_at="{ item }">
					{{ Common.TimeFormat(item.created_at) }}
				</template>
				<template v-slot:show="{ item }">
					{{ item.name??`${item.last_name} ${item.first_name}` }}/{{ item.email }}
				</template>
				<template v-slot:order_really_total="{ item }">
					{{ item.order_really_total }} {{item.currency}}
				</template>
				<template v-slot:status="{ item }">
					<div class="badge text-wrap" :class="{ 'bg-danger' :(item.status == 2), 'bg-success' :(item.status == '1'), 'bg-warning' :(item.status == '0') }">
						{{ $t(`ORDER.STATUS_${item.status}`) }}
					</div>
				</template>
			</DataTable>
		</MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBCard, MDBRow, MDBCol, MDBCardTitle, MDBCardText, MDBCardBody } from 'mdb-vue-ui-kit';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from '@/components/Plugin/DataTable';
import CommonService from "@/core/services/common.service";
import { ref, reactive } from "vue";
import PageLoading from '@/components/Elements/PageLoading';
import { useI18n } from 'vue-i18n';
import ApiService from "@/core/services/api.service";

export default {
  name: "Dashboard",
  components: {
		MDBCard,
		MDBRow,
		MDBCol,
		Breadcrumbs,
		MDBCardTitle,
		MDBCardText,
		MDBCardBody,
		DataTable,
		PageLoading
  },
  setup() {
		const i18n = useI18n();
		const PageLoading = ref(true);
		const Common = CommonService;
		const pagination = ref(false);
		const member = ref([]);
		const order = ref([]);
		const article = ref([]);
		const headers = reactive([
      {
        text: i18n.t("COMMON.ORDERNO"),
        value: "order"
      },
      { text: `${i18n.t("COMMON.NAME")}/${i18n.t("COMMON.ACCOUNT")}`, value: "show", width: 400, sortable: false },
      { text: i18n.t("COMMON.ORDERAMOUNT"), value: "order_really_total" },
      { text: i18n.t("COMMON.ORDERTIME"), value: "created_at" },
      { text: i18n.t("COMMON.ORDERSTATUS"), value: "status", sortable: false }
		]);
		const Dashboard = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Dashboard",
          {
            params: {}
          }
        ).then(response => {
          if (response.status == 200) {
						member.value = response.data.data.member;
						order.value = response.data.data.order;
						article.value = response.data.data.article;
            resolve(response);
          }
        });
      });
    };

    return {
			Common,
			headers,
			PageLoading,
			Dashboard,
			pagination,
			member,
			order,
			article,
    }
  },
  mounted() {
    Promise.all([
			this.Dashboard(),
    ]).then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
  },
};
</script>